import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

export default function Navigation() {
    return (
        <Navbar bg="dark" variant="dark">
            <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id='responsive-navbar-nav'/>
                <Nav>
                    <Nav.Link href='/'>About</Nav.Link>
                    <Nav.Link href="/blog">Blog</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    )
}