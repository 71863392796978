import React from "react";
import Social from "./social";

export default function About(){
    return(
        <div className="container">
            <div className="row align-items-center my-5">
                <div className="col-lg-7">
                    <img
                    className="img-fluid rounded mb-4 mb-lg-0"
                    src={require('../images/torahlabs.png')}
                    alt=""
                    />
                </div>
                <div className="col-lg-5">
                    <h1 className="font-weight-light">Torah Labs</h1>
                    <p>
                        Torah (taw·ruh) in Hebrew means teaching, direction, guidance.<br/><br/>
                        At Torah Labs our endeveour is to help you explore the unknown unknows - beyond the boundary of what you know!
                    </p>
                </div>
            </div>
            <Social/>
        </div>
    );    
}