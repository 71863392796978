import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
    apiKey: "AIzaSyDiFNugckkexf7PAB9ArGb5DlOPd9Y9H6k",
    authDomain: "websites-26fb1.firebaseapp.com",
    projectId: "websites-26fb1",
    storageBucket: "websites-26fb1.appspot.com",
    messagingSenderId: "740671073484",
    appId: "1:740671073484:web:ddd773a8b9eb220473a669",
    measurementId: "G-YWSEVT41EE"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: "BG82dQj4_xbqrBrWwIC7A16Ju-lcC0q7m1Pmw64yCAaJimiCxf9-XC55DVjaQcU3hklQtaM2W2KisJQ7Q_03xtI" }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          console.log('Token generated and received:',currentToken);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });