import React from "react";
import { Form, Input, TextArea, Button} from 'semantic-ui-react';
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';

const SERVICE_ID = 'service_5606oae';
const TEMPLATE_ID = 'template_q7ayl6h';
const USER_ID = 'WSH5fK-vyznbJb_GG';

export default function Contact(){

    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
          .then((result) => {
            console.log(result.text);
            Swal.fire({
              icon: 'success',
              title: 'Message Sent Successfully'
            })
          }, (error) => {
            console.log(error.text);
            Swal.fire({
              icon: 'error',
              title: 'Ooops, something went wrong',
              text: error.text,
            })
          });
        e.target.reset()
      };

    return (
        <div className="App">
          <Form onSubmit={handleOnSubmit}>
            <Form.Field
              id='form-input-control-email'
              control={Input}
              label='Email'
              name='from_email'
              placeholder='Email…'
              required
              icon='mail'
              iconPosition='left'
            />
            <Form.Field
              id='form-input-control-last-name'
              control={Input}
              label='Name'
              name='from_name'
              placeholder='Name…'
              required
              icon='user circle'
              iconPosition='left'
            />
            <Form.Field
              id='form-textarea-control-opinion'
              control={TextArea}
              label='Message'
              name='message'
              placeholder='Message…'
              required
            />
            <Button type='submit' color='green'>Submit</Button>
          </Form>
        </div>
      );
}