import React from "react";
import '../App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram,
    faMedium
  } from "@fortawesome/free-brands-svg-icons";

export default function Social() {
  return (
    <div className="social-container">
        <h5 className="font-weight-light">Follow Us At</h5>
        <a href="https://www.youtube.com/@whydoidothat" className="youtube social">
            <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100092233801646" className="facebook social">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a href="https://www.twitter.com/@_whydoidothat" className="twitter social">
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
        <a href="https://www.instagram.com/_whydoidothat/" className="instagram social">
           <FontAwesomeIcon icon={faInstagram} size="2x" />
       </a>
       <a href="https://medium.com/@whydoidothat" className="medium social">
           <FontAwesomeIcon icon={faMedium} size="2x" />
       </a>
    </div>
  );
}