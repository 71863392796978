import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { requestForToken } from "./firebase";
import Blog from "./components/blog";
import About from "./components/about";
import Contact from "./components/contact";
import Navigation from "./components/navigation";

function Home() {

  requestForToken();
 
  return (
    <div className="home">
    <Navigation/>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<About/>}></Route>
        <Route exact path="/blog" element={<Blog/>}></Route>
        <Route exact path="/contact" element={<Contact/>}></Route>
      </Routes>
    </BrowserRouter>
    </div>  
  );
}

export default Home;