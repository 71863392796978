import React from "react";
import '../App.css';
import { Card, Container, Row } from "react-bootstrap";

const { useState, useEffect } = require("react");
const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@whydoidothat";


function ToText(node){
    let tag= document.createElement("div");
    tag.innerHTML = node;
    node = tag.innerText;
    return node;
}

export default function Blog () {
    const [profile, setProfile] = useState({
        name: "Why Do I Do That",
        profileImage: '',
        profileUrl: ''
    })
    
    const [blog, setBlog] = useState({
        item: [],
        isLoading: true,
        error: null
    })
    
    useEffect(() => {
        fetch(mediumURL)
        .then(res => res.json())
        .then(info => {
            const image = info.feed.image;
            const link = info.feed.link;
            const blogs = info.items;
           // const posts = blogs.filter(post => post.categories.length > 0)

            setProfile(p => ({...p, profileUrl:link, profileImage:image}))
            setBlog({item: blogs, isLoading: false})
        })
        .catch(err => setBlog({error: err.message}))
    }, [])
    
        // To do add Card.Img <Card.Img src={post.thumbnail}  style={{width:'25%' }} variant="top"/>
    const haveBlogs = () => {
        if(blog.item){
            return blog.item.map((post,index) => (
                <div className="container" key={index}>
                    <div>                       
                        <Card style={{ marginTop: '10px', height:'18rem'}} className="h-100">                            
                            <Card.Body className=" flex-column">
                                <Card.Title><Card.Link href={post.link}>{post.title}</Card.Link></Card.Title>
                                <Card.Text>
                                {`${ToText(post.content.substring(0,550))}...`}
                                </Card.Text>     
                                <Card.Footer>
                                    <small className="text-muted">{post.pubDate}</small>
                                </Card.Footer>  
                            </Card.Body>
                        </Card>                    
                    </div>                   
                </div>
            ))
        }
    }

    return(
        <div>
            <Container>
                <Row xs={1} md={2} className="g-4">
                    {blog.isLoading? 'Loading...': haveBlogs()}
                </Row>
            </Container>
        </div>
    )
}